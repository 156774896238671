import { IBoard } from "@vestaboard/installables";
import axios from "axios";

export const API_URL = "https://platform.vestaboard.com";

export const getSubscriptionCreds = async (subscriptionConfigToken: string) => {
  const response = await axios.get(
    `${API_URL}/v2.0/subscription-configuration`,
    {
      headers: {
        "X-Vestaboard-Subscription-Configuration-Token":
          subscriptionConfigToken,
      },
    }
  );

  const data = response.data as any;
  const subscriptionId = data["subscription"]["id"];
  const apiKey = data["subscription"]["installation"]["apiCredential"]["key"];
  const apiSecret =
    data["subscription"]["installation"]["apiCredential"]["secret"];

  return {
    subscriptionId,
    apiKey,
    apiSecret,
  };
};

export const getSubscriptionInfo = async (subscriptionConfigToken: string) => {
  const response = await axios.get(
    `${API_URL}/v2.0/subscription-configuration`,
    {
      headers: {
        "X-Vestaboard-Subscription-Configuration-Token":
          subscriptionConfigToken,
      },
    }
  );

  return response.data as any;
};

export const checkSetupComplete = async (
  boardId: string,
  subscriptionId: string,
  subscriptionConfigToken: string
) => {
  const response = await axios.post("/api/checksetup", {
    boardId,
    subscriptionId,
    subscriptionConfigToken,
  });
  const data = response?.data as {
    setupComplete: boolean;
    defaultCharacters: IBoard;
    messageSetId: string;
    intervalMinutes: number;
  };
  return data;
};

export const updateSubscriptionTitle = async (
  subscriptionId: string,
  subscriptionConfigToken: string,
  newTitle: string
) => {
  await axios.post("/api/subscription", {
    subscriptionId,
    subscriptionConfigToken,
    newTitle,
  });
};
export interface IMessageSetIndex {
  id: string;
  title: string;
  description: string;
  installable: string;
  messageSet: {
    id: string;
  };
}

export const getMessageSetIndex = async () => {
  const response = await axios.get(`${API_URL}/v3.0/message-set-index`);
  return response.data as Array<IMessageSetIndex>;
};

export const displayNow = async (
  boardId: string,
  subscriptionConfigToken: string
) => {
  await axios.post("/api/display-now", {
    subscriptionConfigToken,
    boardId,
  });
};
