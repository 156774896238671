import { useEffect, useState } from "react";
import {
  Body,
  SubTitle,
} from "@vestaboard/installables/lib/components/Typography";
import {
  ActionButton,
  BoardPreview,
  Button,
  createBoard,
  Divider,
  IBoard,
  Modal,
  NotificationBanner,
  Select,
  Spacer,
  useToasts,
} from "@vestaboard/installables";
import { makeVestaboardStyles } from "@vestaboard/installables/lib/hooks/useVestaboardStyles";

import { Box, CircularProgress } from "@mui/material";
import { NativeMessageEditor } from "../components/BoardEditorModal";
import { checkSetupComplete, displayNow } from "../api";
import { useBitsaver } from "../hooks/useBitsaver";
import { useSubscription } from "../hooks/useSubscription";
import { useIsQuietHours } from "../hooks/useQuietHours";

enum MESSAGE_TYPE {
  customMessage = "customMessage",
  channel = "channel",
}

const useStyles = makeVestaboardStyles({
  section: {
    width: "100%",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    paddingTop: "24px",
    marginLeft: "16px",
    marginRight: "16px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    padding: "16px",
    paddingTop: "32px",
    paddingBottom: "32px",
  },
  descriptionContainer: {
    textAlign: "left",
    width: "100%",
    maxWidth: 600,
  },
});

export const Settings = () => {
  const classes = useStyles();

  const [openPreview, setOpenPreview] = useState(false);

  const [, setMessageType] = useState(
    `${MESSAGE_TYPE.customMessage}`
  );
  const [intervalMinutes, setIntervalMinutes] = useState("");
  const [, setMessageSetId] = useState("");
  const [setupComplete, setSetupComplete] = useState(false);
  const [boardValue, setBoardValue] = useState<IBoard>(createBoard());

  const { saveBitsaver, error } = useBitsaver();

  const {
    boardId,
    subscriptionConfigToken,
    subscriptionId,
    boardStyle,
    loading,
  } = useSubscription();
  const { isQuietHours, formattedQuietHours } = useIsQuietHours({
    boardId,
  });
  // TODO: reenable message set defaults when decided
  // const { messageSets } = useMessageSets();
  const { addToast } = useToasts();

  useEffect(() => {
    boardId &&
      subscriptionConfigToken &&
      subscriptionId &&
      checkSetupComplete(boardId, subscriptionId, subscriptionConfigToken).then(
        ({
          setupComplete,
          messageSetId,
          defaultCharacters,
          intervalMinutes,
        }) => {
          setSetupComplete(setupComplete);
          if (messageSetId) {
            setMessageType(`${MESSAGE_TYPE.channel}`);
            setMessageSetId(messageSetId);
          }
          intervalMinutes && setIntervalMinutes(`${intervalMinutes}`);
          defaultCharacters && setBoardValue(defaultCharacters);
        }
      );
  }, [boardId, subscriptionId, subscriptionConfigToken]);

  if (loading) {
    return (
      <Box sx={classes.content}>
        <Box sx={classes.descriptionContainer}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <>
      {/* @ts-ignore */}
      <Box sx={classes.content}>
        {/* @ts-ignore */}
        <Box sx={classes.descriptionContainer}>
          <NotificationBanner
            text={formattedQuietHours}
            visible={isQuietHours}
          />
          <SubTitle>Description</SubTitle>
          <Spacer size={"large"} />
          <Body>
            Set a default message for your Vestaboard to return to after a set
            period of inactivity.
          </Body>
          <Spacer size={"medium"} />
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <ActionButton
              onClick={() => {
                setOpenPreview(true);
              }}
            >
              Preview
            </ActionButton>
            <div style={{ width: 16 }}></div>
            <ActionButton
              disabled={!setupComplete}
              onClick={async () => {
                if (isQuietHours) {
                  addToast(formattedQuietHours, {
                    appearance: "error",
                  });
                  return;
                }

                subscriptionConfigToken &&
                  (await displayNow(boardId, subscriptionConfigToken));
              }}
            >
              Display Now
            </ActionButton>
          </Box>
          <Spacer size={"large"} />
          <Divider />
          <Spacer size={"medium"} />
          <SubTitle>Default Message</SubTitle>
          <Spacer size={"medium"} />
        </Box>
        {error && (
          <>
            <Body>{error}</Body>
            <Spacer size={"medium"} />
          </>
        )}
        <Box sx={classes.section}>
          <NativeMessageEditor
            boardValue={boardValue}
            boardStyle={boardStyle}
            setBoardValue={setBoardValue}
            setupComplete={setupComplete}
            openText={`Edit Default Message`}
          />
          <Spacer size={"large"} />
        </Box>

        <Box sx={classes.section}>
          <Select
            options={[
              {
                id: "1",
                name: "Minute",
              },
              {
                id: "5",
                name: "5 Minutes",
              },
              {
                id: "10",
                name: "10 Minutes",
              },
              {
                id: "15",
                name: "15 Minutes",
              },
              {
                id: "20",
                name: "20 Minutes",
              },
              {
                id: "30",
                name: "30 Minutes",
              },
              {
                id: "60",
                name: "Hour",
              },
            ]}
            value={intervalMinutes}
            onValueChange={setIntervalMinutes}
            label="Display After"
          />
          <Spacer size={"large"} />

          <Button
            buttonType="white"
            disabled={!intervalMinutes}
            onClick={async () => {
              if (subscriptionId && subscriptionConfigToken) {
                await saveBitsaver(
                  subscriptionId,
                  subscriptionConfigToken,
                  parseInt(intervalMinutes),
                  boardValue,
                  undefined
                );
                setSetupComplete(true);
                addToast(`Default Message Set`, {
                  appearance: "success",
                });
              }
            }}
          >
            {"Save Changes"}
          </Button>
        </Box>
        <Spacer size={"large"} />
      </Box>
      <Modal
        visible={openPreview}
        transparent
        fullScreenMobile
        onClose={() => {
          setOpenPreview(false);
        }}
      >
        <Box
          style={{
            padding: 16,
          }}
        >
          <BoardPreview
            isFavorite={false}
            boardStyle={boardStyle}
            characters={[
              [
                67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67,
                67, 67, 67, 67, 67, 67,
              ],
              [
                66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66,
                66, 66, 66, 66, 66, 66,
              ],
              [
                0, 0, 0, 14, 5, 20, 23, 15, 18, 11, 50, 0, 12, 15, 2, 2, 25, 0,
                0, 0, 0, 0,
              ],
              [
                0, 0, 0, 16, 23, 50, 0, 9, 14, 19, 16, 9, 18, 5, 27, 28, 29, 0,
                0, 0, 0, 0,
              ],
              [
                66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66, 66,
                66, 66, 66, 66, 66, 66,
              ],
              [
                67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67, 67,
                67, 67, 67, 67, 67, 67,
              ],
            ]}
          />
        </Box>
      </Modal>
    </>
  );
};
