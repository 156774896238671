import {
  FullModal,
  IBoard,
  NativeVisualEditorBridge,
  createBoard,
  BoardPreview,
  BoardPreviewMask,
  IBoardStyle,
} from "@vestaboard/installables";
import { useFormat } from "../hooks/useFormat";
import { useEffect, useState } from "react";

interface IBoardProps {
  openText: string;
  boardValue: IBoard;
  setBoardValue: (boardValue: IBoard) => void;
  setupComplete: boolean;
  boardStyle?: IBoardStyle;
}

export const NativeMessageEditor = (props: IBoardProps) => {
  const { format } = useFormat();
  const { boardValue, setBoardValue } = props;
  const [visualEditorId, setVisualEditorId] = useState<string | null>(null);
  useEffect(() => {
    // poll for message
    if (visualEditorId) {
      const interval = setInterval(() => {
          try {
            fetch(
              `https://platform.vestaboard.com/v3.0/visual-editor-session/${visualEditorId}`
            ).then(async (response) => {
              if (!response.ok) {
                // message not done being edited yet, dont throw
                // throw new Error("Network response was not ok");
                return
              }
              const data = (await response.json()) as {
                characters: string;
              };
              if (!data.characters) {
                return;
              }
              setBoardValue(JSON.parse(data.characters));
              clearInterval(interval);
              setVisualEditorId(null);
            });
          } catch (e) {}
      }, 1000);
    }
  }, [visualEditorId, setVisualEditorId, setBoardValue]);

  return (
    <NativeVisualEditorBridge
      connectionTimeout={1000}
      maxReconnectionDelay={200}
      minReconnectionDelay={200}
      setVisualEditorId={setVisualEditorId}
      // debugWs={true}
      boardStyle={props.boardStyle}
      onComplete={async (_id, text, characters) => {
        const messageCharacters = characters
          ? (JSON.parse(characters) as IBoard)
          : text
          ? await format(text)
          : createBoard();
        setBoardValue(messageCharacters);
      }}
      renderWebVisualEditor={(open, editor, setOpen) => {
        return open ? (
          <FullModal
            onClose={() => {
              if (setOpen) {
                setOpen(false);
              }
            }}
          >
            {editor}
          </FullModal>
        ) : (
          <div />
        );
      }}
    >
      {(launch) => (
        <>
          <BoardPreviewMask
            handleDuplicateTitle={props.openText}
            defaultShowMask={!props.setupComplete}
            handleDuplicate={() => {
              launch(JSON.stringify(boardValue));
            }}
          >
            <BoardPreview
              isFavorite={false}
              characters={boardValue}
              boardStyle={props.boardStyle}
            />
          </BoardPreviewMask>
        </>
      )}
    </NativeVisualEditorBridge>
  );
};
