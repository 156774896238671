import { IBoard } from "@vestaboard/installables";
import { useCallback } from "react";
import { API_URL } from "../api";

export const useFormat = () => {
  const format = useCallback(async (text: string) => {
    try {
      const message = JSON.parse(text);
      if (typeof message?.[0]?.[0] !== "number") {
        throw new Error();
      }
      return message;
    } catch (err) {
      const message = (await fetch(`${API_URL}/format`, {
        method: "POST",
        body: JSON.stringify({
          message: text,
        }),
      }).then((res) => res.json())) as IBoard;

      return message;
    }
  }, []);

  return {
    format,
  };
};
