import { useCallback, useState } from "react";
import { getSubscriptionInfo } from "../api";
import axios from "axios";
import { IBoard } from "@vestaboard/installables";

export const useBitsaver = () => {
  const [error, setError] = useState("");

  const saveBitsaver = useCallback(
    async (
      subscriptionId: string,
      subscriptionConfigToken: string,
      intervalMinutes: number,
      defaultCharacters?: IBoard,
      messageSetId?: string
    ) => {
      const { subscription } = await getSubscriptionInfo(
        subscriptionConfigToken
      );
      const boardId = subscription.board[0].id;
      const boardTitle = subscription.board[0].title;
      if (boardTitle && boardId && subscriptionConfigToken && subscriptionId) {
        await axios.post("/api/bitsaver", {
          intervalMinutes,
          subscriptionConfigToken,
          defaultCharacters: defaultCharacters || undefined,
          messageSetId: messageSetId || undefined,
          boardId,
          subscriptionId,
        });
        setError("");
        return true;
      } else {
        setError("There was an error authorizing your Vestaboard");
        return false;
      }
    },
    []
  );

  const removeBitsaver = useCallback(
    async (
      subscriptionId: string,
      subscriptionConfigToken: string,
      boardId: string
    ) => {
      if (subscriptionConfigToken && subscriptionId) {
        await axios.delete("/api/bitsaver", {
          data: {
            subscriptionConfigToken,
            subscriptionId,
            boardId,
          },
        });
        setError("");
        return true;
      } else {
        setError("There was an error deleting your default message.");
        return false;
      }
    },
    []
  );
  return {
    error,
    saveBitsaver,
    removeBitsaver,
  };
};
